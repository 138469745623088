import React, { useEffect, useState } from "react";
import Client from '../Landing/client';
import { gsap } from "gsap";
import Header from "../../about/header.png"
import { useTranslation } from 'react-i18next';
import SectionImage from "../../about/section.png";
import FooterImage from "../../about/footer.png";
import PlayStoreLogo from "../../playstore.png"
import './about.css'
import { Link } from "react-router-dom";

const AboutComp = () => {  
        const { t } = useTranslation();
        const aboutCardMainServices = t('aboutCardMainServices', { returnObjects: true });
        const aboutCardSupportingOnes = t('aboutCardSupportingOnes', { returnObjects: true });
        const aboutCardSupportingTwos = t('aboutCardSupportingTwos', { returnObjects: true });
        const CLevel = t('CLevel', { returnObjects: true });


        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'left') {
                        animationProps.x = 60;
                } else {
                        animationProps.x = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };


        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'left';
                        if (index % 2 === 0) {
                                direction = 'right';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);

        const [selectedLevel, setSelectedLevel] = useState(null);

        const openModal = (level) => {
                setSelectedLevel(level);
        };

        const closeModal = () => {
                setSelectedLevel(null);
        };
        
        return (  
                <>
                        <section className="pb-first">
                                <article>
                                        <div className="container">
                                                <div className="row margin-top margin-bottom mb-5">
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex align-items-center fade-in-column">
                                                                <div>
                                                                        <h1 className="fs-0 text-color-blue ">{t('aboutContent.header')}</h1>
                                                                        <p className="m-0 fw-semibold fs-4 fst-italic text-color-blue">Connection - Engagement - Authencity</p>
                                                                        <p className="fs-4 text-muted sub-title">{t('aboutContent.desc')}</p> 
                                                                </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center fade-in-column ">
                                                                <img src={Header} alt="hexagon logo" className="hexagon-logo"/>
                                                        </div>
                                                </div>

                                        </div>
                                </article>
                        </section>
                        <section>
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <h1 className="text-center fw-bold pb-5">{t('aboutContent.deliver')}</h1>
                                        <div className="row mt-5">
                                                <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center mb-5">
                                                        <div className="shadow-box p-5 w-75 rounded-4">
                                                                <p className="fs-4 fw-semibold text-center">{t('aboutContent.vision')}</p>
                                                                <p className="text-center">{t('aboutContent.visionContent')}</p>
                                                        </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center mb-5">
                                                        <div className="shadow-box p-5 w-75 rounded-4">
                                                                <p className="fs-4 fw-semibold text-center">{t('aboutContent.mission')}</p>
                                                                <p className="text-center">{t('aboutContent.missionContent')}</p>
                                                        </div>
                                                </div>
                                        </div> 
                                </div>
                        </section>
                        <section className="blue-color-bg">
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <div className="row py-5">
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <h1 className="fs-1 fw-bold text-center">{t('aboutContent.adventage')}</h1>
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <img src={SectionImage} alt="hexagon logo" className="w-75"/>
                                                </div>
                                        </div>
                                </div>
                        </section>      
                        <section className="mix-bg">
                                <div className="container margin-top margin-bottom text-color-blue">
                                        <div className="margin-top margin-bottom">
                                                <h1 className="text-center fw-bold fs-2 mb-5">{t('aboutContent.mainService')}</h1>
                                                <div className="row">
                                                        {aboutCardMainServices.map((aboutCardMainService, index) => (
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-5">
                                                                        <a href={aboutCardMainService.link} className="text-decoration-none text-color-blue">
                                                                                <div key={index} className="center-card">
                                                                                        <img src={require(`../../about/card/${aboutCardMainService.imageSrc}`)} alt="card-logo" className="icon-card-about"/>
                                                                                        <div className="card fixed-height shadow-box rounded-4">
                                                                                                <div className="card-body py-5">
                                                                                                        <h5 className="fw-bold text-center fs-3 mt-4">{aboutCardMainService.title}</h5>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </a>
                                                                        
                                                                </div>
                                                        ))}
                                                </div>
                                        </div>
                                        <div className="margin-top margin-bottom">
                                                <h1 className="text-center fw-bold fs-2 mb-5">{t('aboutContent.supportingService')}</h1>
                                                <div className="row">
                                                        {aboutCardSupportingOnes.map((aboutCardMainService, index) => (
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 py-5">
                                                                        <a href={aboutCardMainService.link} className="text-decoration-none text-color-blue">
                                                                                <div key={index} className="center-card-about w-100">
                                                                                        <img src={require(`../../about/card/${aboutCardMainService.imageSrc}`)} alt="card-logo" className="icon-card-about"/>
                                                                                        <div className="card fixed-height shadow-box rounded-4">
                                                                                                <div className="card-body py-5">
                                                                                                        <h5 className="fw-bold text-center fs-3 mt-4">{aboutCardMainService.title}</h5>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </a>
                                                                </div>
                                                        ))}
                                                        {aboutCardSupportingTwos.map((aboutCardMainService, index) => (
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-5">
                                                                        <a href={aboutCardMainService.link} className="text-decoration-none text-color-blue">
                                                                                <div key={index} className="center-card">
                                                                                        <img src={require(`../../about/card/${aboutCardMainService.imageSrc}`)} alt="card-logo" className="icon-card-about"/>
                                                                                        <div className="card fixed-height shadow-box rounded-4">
                                                                                                <div className="card-body py-5">
                                                                                                        <h5 className="fw-bold text-center fs-3 mt-4">{aboutCardMainService.title}</h5>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </a>
                                                                </div>
                                                        ))}
                                                </div>
                                        </div>
                                </div>
                        </section>

                        {/* <section className="blue-color-bg">
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <div className="row py-5 ">
                                                <h5 className="text-center fs-2 text-color-blue fw-bold">{t('aboutContent.header')}</h5>
                                                {CLevel.map((level, index) => (
                                                        <div className="col-md-3 col-lg-3 col-sm-6 d-flex justify-content-center align-items-center mt-5" >
                                                                <div key={index} onClick={() => openModal(level)}>
                                                                        <img src={require(`../../about/cLevel/${level.imageSrc}`)} alt="c-photos" className="c-level-style"/>
                                                                        <p className="text-center m-0 fw-semibold fs-4 mt-4">{level.name}</p>
                                                                        <p className="text-center">{level.title}</p>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>   */}
                        <section>
                                <div className="container margin-top margin-bottom">
                                        <div className="row py-5">
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <img src={FooterImage} alt="hexagon logo" className="w-100"/>
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <div>
                                                                <h3 className="text-color-blue fw-bold fs-1">{t('aboutContent.available')}</h3>
                                                                <Link className="nav-link" to={`https://play.google.com/store/apps/details?id=asia.zeals.mobile.pwa`}>
                                                                        <img src={PlayStoreLogo} alt="Logo" className="w-50"/>
                                                                </Link>
                                                        </div>
                                                        
                                                </div>
                                        </div>
                                </div>
                        </section>
                        {selectedLevel && (
                                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                        <div className="modal-header">
                                                                <h5 className="modal-title">{selectedLevel.name}</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                        </div>
                                                        <div className="modal-body">
                                                                <p>{selectedLevel.description}</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        )}
                </>
        );
};

export default AboutComp;
