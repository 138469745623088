import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const Axios = axios.create({
  baseURL: 'https://app.zeals.asia/api/v1/',
});

const formatCategoryName = (originalName) => {
        const categoryMapping = {
                digitalStrategy : 'Digital Strategy',
                websiteInnovation : 'Website Innovation',
                eCommerce : 'E-commerce Integration',
                sosMed : 'Social Media and Community Engagement',
                content: 'Content creator',
                customer : 'Customer-Centric and Analytics Digital Services',
                socialImpact : 'Social Impact Responsibility',
                resilience : 'Resilience and Endurance',
                inovation : 'Innovation and Creativity',
                surprise : 'Surprise Factor',
        };
        return categoryMapping[originalName] || originalName;
};

const DetailAdmin = ( ) => {
        const [detailData, setDetailData] = useState({});
        const [companyInformation, setCompanyInformation] = useState("");


        const { id } = useParams();
        useEffect(() => {
                async function getDetailItem() {
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
                        try {
                                const response = await Axios.get(`get-total-score/${id}`);
                                const companyInfo = response.data.companyInfo;
                                const allDetailData = companyInfo.map((info) => {
                                        const data = JSON.parse(info.score);
                                        return data;
                                });
                                setDetailData(allDetailData);
                                setCompanyInformation(companyInfo)
                        } catch (error) {
                                console.error('Error fetching detail data:', error);
                        }
                }
                if (id) {
                        getDetailItem();
                }
        }, [id]);
        const calculateTotalPointsAndDivide = (categoryName) => {
                if (detailData && detailData.length > 0) {
                        const allAspects = detailData.map((data) => {
                                const category = data[categoryName];
                                if (category) {
                                        const aspects = Object.keys(category).filter((aspect) => aspect !== 'juryNotes').map((aspect) => category[aspect]);
                                
                                        const totalWithoutJuryNotes = aspects.reduce((acc, value) => acc + value, 0);
                                
                                        const totalAspectsWithoutJuryNotes = Object.keys(category).length - 1;
                                
                                        const totalAfterDivision = totalWithoutJuryNotes / totalAspectsWithoutJuryNotes;

                                        return totalAfterDivision;
                                }
                                return 0;
                        });
                        return allAspects;
                }
        };

        const categoryMapping = {
                digitalStrategy: 'digitalStrategy',
                websiteInnovation: 'websiteInnovation',
                eCommerce: 'eCommerce',
                sosMed: 'sosMed',
                content: 'content',
                customer: 'customer',
                socialImpact: 'socialImpact',
                resilience: 'resilience',
                inovation: 'inovation',
                surprise: 'surprise',
        };

        const categoryTotals = {};

        Object.keys(categoryMapping).forEach((key) => {
                if(categoryMapping){
                        const totalPoints = calculateTotalPointsAndDivide(key);
                        categoryTotals[categoryMapping[key]] = totalPoints;
                }
        });
        return(
                <>
                        <section>
                                <div className='container w-75 mx-auto'>
                                        <div className="h4 pb-2 mb-4 mt-5 border-bottom border-dark text-center text-uppercase">
                                                {companyInformation.length === 0 ? 'Company scores have not been entered yet' : companyInformation[0].company_name + ' detail score'} 
                                        </div>
                                        <table className="table table-bordered">
                                                <tbody>
                                                        {Object.keys(categoryTotals).map((categoryName) => (
                                                                <React.Fragment key={categoryName}>
                                                                        <tr>
                                                                                <td colSpan="2" className='text-center fw-semibold fs-5'>{formatCategoryName(categoryMapping[categoryName])}</td>
                                                                        </tr>
                                                                        <tr>
                                                                                <th scope="col">Jury</th>
                                                                                <th scope="col" className='text-center'>Total Score</th>
                                                                        </tr>
                                                                        {
                                                                                categoryTotals[categoryName] ? 
                                                                                        Object.keys(categoryTotals[categoryName]).map((key, index) => (
                                                                                                <tr key={key}>
                                                                                                        <td>{companyInformation[index].full_name}</td>
                                                                                                        <td className='text-center'>{categoryTotals[categoryName][key]}</td>
                                                                                                </tr>
                                                                                        ))
                                                                                :
                                                                                        <tr>
                                                                                                <td colSpan="2" className='text-center'>No Data Founded!</td>
                                                                                        </tr>
                                                                        }
                                                                </React.Fragment>
                                                        ))}
                                                </tbody>
                                        </table>
                                </div>
                        </section>
                </>
        );
}

export default DetailAdmin
