// import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../data.json";
import Form from "../../Components/Form";
import Testimoni from "../../Components/Testimoni";
import InfluenceConnectComp from "../../Components/InfluenceConnectComp";

const InfluenceConnect = () => {
  const location = useLocation();

  let language = [];
  language = Data[location.hash];

  if (location.hash === "") {
    language = Data["#en"];
  }

  // let meta_description = '<meta name="description" content="' + language.meta.description + '"/>';

  return (
    <>
      <main>
        <InfluenceConnectComp />
        {/* <Testimoni /> */}
        {/* <Form /> */}
      </main>
    </>
  );
};

export default InfluenceConnect;
