import React from "react";
import Data from "../../../data.json";
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const Counting = () => {  
        const { t } = useTranslation();
        const countings = t('counting', { returnObjects: true });
        return (  
                <>
                        <div class="arrow_box">
                                <div className="container">
                                        <div className="row counted-row rounded-pill px-5 bg-light mb-5 pb-3">
                                                {countings.map((counting, index) => (
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6" key={index}> 
                                                                <div className="counted-box d-flex justify-content-center align-items-center ">
                                                                        <div>
                                                                                <p className="text-center m-0 fw-bold  d-flex align-items-center justify-content-center">
                                                                                        <CountUp 
                                                                                                start={0} 
                                                                                                end={counting.number}
                                                                                                duration={2}
                                                                                                className="fs-counter text-muted counted"
                                                                                        />
                                                                                        <span className="fs-1 text-muted">+</span> 
                                                                                </p>
                                                                                <p className="m-0 text-center text-uppercase desc">{counting.text}</p>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </div>   
                </>
        );
};

export default Counting;
