import React, {useEffect, useState}  from "react";
import axios from 'axios'
import Swal from "sweetalert2";

export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

const ListCompany = () => {
        const [company, setCompany] = useState([]);
        const [newCompanyName, setNewCompanyName] = useState("");
        const [selectedOption, setSelectedOption] = useState("");
        const [addCompany, setAddCompany] = useState(false);


        useEffect(() => {
                async function getJudgerList(){
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                        try {
                                const response = await Axios.get('get-company');
                                setCompany(response.data.company);
                        } catch (error) {
                                console.error('Error fetching scoring data:', error);
                        }    
                }
                getJudgerList();
        },[]);

        const handleInputChange = (e) => {
                setNewCompanyName(e.target.value);
        };

        const handleSelectedChange = (e) => {
                setSelectedOption(e.target.value);
        };

        const handleAddCompany = () => {
                setAddCompany(true);
        };

        const handleSubmit = async (e) => {
                e.preventDefault();
                try {
                        Swal.showLoading();
                        const response = await Axios.post("add-company", {
                                company_name: newCompanyName,
                                award_nomination: selectedOption,
                        });

                        Swal.fire({
                                icon: "success",
                                title: "Company Added Successfully!",
                                text: response.data.message,
                        });

                        setNewCompanyName("");
                        setCompany([...company, response.data]);
                } catch (error) {
                        console.error("Error adding company:", error);
                        Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Failed to add company. Please try again.",
                        });
                }
        };

        const handleDelete = async (companyId) => {
                try {
                        Swal.showLoading();
            
                        await Axios.get(`delete-company/${companyId}`);

                        const updatedCompanyList = company.filter((item) => item.id !== companyId);
                        setCompany(updatedCompanyList);
            
                        Swal.fire({
                                icon: "success",
                                title: "Company Deleted Successfully!",
                                text: "The selected company has been deleted.",
                        });
                } catch (error) {
                        console.error("Error deleting company:", error);
            
                        Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Failed to delete company. Please try again.",
                        });
                } 
        };

        return (
                <>
                        {
                                addCompany ? 
                                        <section>
                                                <div className="container w-75 mx-auto mt-5">
                                                        <div class="h4 pb-2 mb-4 border-bottom border-dark text-center text-uppercase">
                                                                Add Company Participant
                                                        </div>
                                                        <form onSubmit={handleSubmit} >
                                                                <div className="mb-3">
                                                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                                                Company Name
                                                                        </div>
                                                                        <input type="text" className="form-control" id="company_name" name="company_name" value={newCompanyName} onChange={handleInputChange} placeholder="Company Name" required/>
                                                                </div>
                                                                <div className="mb-3">
                                                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                                                Award Category
                                                                        </div>
                                                                        <select class="form-select" aria-label="select" onChange={handleSelectedChange} value={selectedOption} required>
                                                                                <option value="" disabled selected>Select Award Category</option>
                                                                                <option value="Digital Transformation Excellence">Digital Transformation Excellence</option>
                                                                                <option value="Social Media Mastery">Social Media Mastery</option>
                                                                                <option value="Content Innovation and Online Marketing Powers">Content Innovation and Online Marketing Powers</option>
                                                                                <option value="Customer-Centric Digital Services">Customer-Centric Digital Services</option>
                                                                                <option value="Social Impact Responsibility Champion">Social Impact Responsibility Champion</option>
                                                                        </select>
                                                                </div>
                                                                <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 mb-3 w-100 mt-3">ADD COMPANY</button>
                                                        </form>
                                                </div>
                                        </section>
                                : 
                                        <section>
                                                <div className="container w-75 mx-auto mt-5">
                                                        <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 mb-3 w-100 mt-3" onClick={handleAddCompany}>CLICK HERE TO ADD COMPANY</button>
                                                </div>
                                        </section>

                        }
                        <section>
                                <div className="container w-75 mx-auto mt-5">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark text-center text-uppercase">
                                                Company List Table
                                        </div>
                                        <table className="table">
                                                <thead>
                                                        <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className="text-center">Award Nomination</th>
                                                                <th scope="col" className="text-center">Action</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {company.map((item, index) => (
                                                                <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{item.company_name}</td>
                                                                        <td className="text-center">{item.award_nomination}</td>
                                                                        <td className="d-flex justify-content-center">
                                                                                <button type="button" class="btn btn-danger rounded-pill fw-semibold text-uppercase" onClick={() => handleDelete(item.id)}>Delete</button>
                                                                        </td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                        </table>
                                </div>
                        </section>
                </>
        );
        
};

export default ListCompany;
