import axios from "axios";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./form.css"
import { useLocation } from 'react-router-dom';
import Data from "../../data.json";
import { Link } from "react-router-dom";

const Form = () => {
        const [loading, setLoading] = useState(false);

        const [form, setForm] = useState({
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                notes: "",
        });

        const handleChange = (e) => {
                setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                });
        };

        const handleContactUs = async (e) => {
                e.preventDefault();
                setLoading(true);
                try {
                        const response = await axios.post("https://app.zeals.asia/api/apiv1/maildemo", {
                                first_name: form.first_name,
                                last_name: form.last_name,
                                email: form.email,
                                phone_number: form.phone_number,
                                notes: form.notes,
                        });
                    
                        const result = response.data;
                        console.log("response", result);
                } catch (err) {
                        console.log(err);
                }
        };
        return (
                <>
                        <section className="mix-bg">
                                <div className="container p-5 text-header" >
                                        <div className="margin-top">
                                                <h1 className="fs-1 fw-bold text-color-blue text-center">How Can We Help?</h1>
                                        </div>
                                </div>
                        </section>
                        <section className="form-section ">
                                <div className="container">
                                        <div className="row">
                                                <Link to={`https://wa.me/+62816779928`} className="text-decoration-none">
                                                        <div className="d-flex shadow-box p-5 mb-5">
                                                                <div className="d-flex justify-content-center align-items-center font-awesome">
                                                                        <div className="fs-4 font-awesome-form">
                                                                                <FontAwesomeIcon icon={faPhone} fixedWidth />
                                                                        </div>
                                                                </div>
                                                                <div>
                                                                        <p className="fs-6 fw-semibold text-muted m-0">Call & Message us directly at our WhatsApp</p>
                                                                        <p className="fs-3 fw-bold mt-1" style={{ color : '#313286'}}>+62 816-779-928</p>
                                                                </div>
                                                        </div>
                                                </Link>
                                                <div className="mb-5">
                                                        <div className="shadow-box p-5">
                                                                <div className="mb-5">
                                                                        <h3 className="fw-bold fs-3 text-center mb-5 text-color-blue">Please share your requirement</h3>
                                                                        <p className="text-center text-muted">Share your information and needs to us, and we can schedule a meeting to talk about what you needs. </p>
                                                                </div>
                                                                <form className="g-3 rounded-4 form">
                                                                        <div className="row">
                                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                                                                        <label htmlFor="name" className="form-label text-uppercase fw-semibold">first name <span>*</span></label>
                                                                                        <input type="text" className="form-control form-input" id="name" placeholder="First Name" name="first_name" value={form.first_name} onChange={handleChange} required/>
                                                                                </div>
                                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                                                                        <label htmlFor="name" className="form-label text-uppercase fw-semibold">last Name <span>*</span></label>
                                                                                        <input type="text" className="form-control form-input" id="name" placeholder="Last Name" name="last_name" value={form.last_name} onChange={handleChange} required/>
                                                                                </div>
                                                                        </div>
                                                                        <div className="row mt-3">
                                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                                                                        <label htmlFor="email" className="form-label text-uppercase fw-semibold">Email <span>*</span></label>
                                                                                        <input type="email" className="form-control form-input" id="email"  placeholder="Email" name="email" value={form.email} onChange={handleChange} required/>
                                                                                </div>
                                                                                <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                                                                                        <label htmlFor="phone" className="form-label text-uppercase fw-semibold">phone number <span>*</span></label>
                                                                                        <input type="number" className="form-control form-input" id="phone"  placeholder="Phone Number"  name="phone_number" value={form.phone_number} onChange={handleChange} required/>
                                                                                </div>
                                                                        </div>
                                                                        <div className="mb-3 mt-3">
                                                                                <label htmlFor="notes" className="form-label text-uppercase fw-semibold">your message <span>*</span></label>
                                                                                <textarea className="form-control form-input" id="notes" rows="3"  placeholder="What can we help you?" name="notes" value={form.notes} onChange={handleChange} required></textarea>
                                                                        </div>
                                                                        <div className="col-12 mt-5">
                                                                                <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-4 w-25 mb-3" onClick={handleContactUs} disabled={!form}>Get in Touch!</button>
                                                                        </div>
                                                                </form>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </section>
                </>
                
        )
}

export default Form
