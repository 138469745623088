import React, {useEffect, useState}  from "react";
import axios from 'axios'
import Swal from "sweetalert2";

export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

const ListJudger = () => {
        const [judger, setJudger] = useState([]);
        useEffect(() => {
                async function getJudgerList(){
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                        try {
                                const response = await Axios.get('get-list-judger');
                                setJudger(response.data.user);
                        } catch (error) {
                                console.error('Error fetching scoring data:', error);
                        }    
                }
                getJudgerList();
        },[]);

        const refreshJudgerList = async () => {
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                try {
                        const response = await Axios.get('get-list-judger');
                        setJudger(response.data.user);
                } catch (error) {
                        console.error('Error fetching updated judging data:', error);
                }
        };

        const handleDeactive = async (id) => {
                try {
                        Swal.showLoading();
            
                        await Axios.post(`deactive-jury/${id}`);
                        await refreshJudgerList();
                        Swal.fire({
                                icon: "success",
                                title: "Jury Deactivated!",
                                text: "Jury has been deactivate.",
                        });
                } catch (error) {
                        console.error("Error deleting company:", error);
            
                        Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Failed to deactive jury account. Please try again.",
                        });
                } 
        };
        if(judger.length === 0){
                return <h2 className="text-center fw-semibold mt-5">No user registered yet</h2>
        }else{
                return (
                        <>
                                <section>
                                        <div className="container w-75 mx-auto mt-5">
                                                <div class="h4 pb-2 mb-4 border-bottom border-dark text-center text-uppercase">
                                                        List of Jury
                                                </div>
                                                <table className="table">
                                                        <thead>
                                                                <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col" className="text-center">Email</th>
                                                                        <th scope="col" className="text-center">Status</th>
                                                                        <th scope="col" className="text-center">Action</th>

                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                {judger.map((item, index) => (
                                                                        <tr key={index}>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-center">{item.email}</td>
                                                                                <td className="text-center">{item.status}</td>
                                                                                <td className="d-flex justify-content-center">
                                                                                        <button type="button" class="btn btn-warning rounded-pill fw-semibold text-uppercase" onClick={() => handleDeactive(item.id)} disabled={item.status === "inactive"}>Deactive account</button>
                                                                                </td>
                                                                        </tr>
                                                                ))}
                                                        </tbody>
                                                </table>
                                        </div>
                                </section>
                        </>
                );
        }
        
};

export default ListJudger;
