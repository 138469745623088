import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const Axios = axios.create({
  baseURL: 'https://app.zeals.asia/api/v1/',
});

const formatCategoryName = (originalName) => {
        const categoryMapping = {
                digitalStrategy : 'Digital Strategy',
                websiteInnovation : 'Website Innovation',
                eCommerce : 'E-commerce Integration',
                sosMed : 'Social Media and Community Engagement',
                content: 'Content creator',
                customer : 'Customer-Centric and Analytics Digital Services',
                socialImpact : 'Social Impact Responsibility',
                resilience : 'Resilience and Endurance',
                inovation : 'Innovation and Creativity',
                surprise : 'Surprise Factor',
        };
        return categoryMapping[originalName] || originalName;
};

const formatPropertyName = (originalName, category) => {
        const propertyMappings = {
                digitalStrategy : {
                        clarity: 'Clarity and innovation of strategy',
                        alignment: 'Alignment with business objectives',
                        roadmap: 'Demonstrated roadmap for transformation',
                        juryNotes: 'Jury Notes'
                },
                
                websiteInnovation : {
                        uiDesign: 'UI Design and user experience',
                        functionality: 'Website functionality',
                        impact: 'Demonstrated impact on brand growth and sales',
                        juryNotes: 'Jury Notes'
                },
                
                eCommerce : {
                        e2e: 'End-to-end Integration (including offline and online)',
                        demonstrated: 'Demonstrated incorporation of emerging trends and technologies to maintain a competitive edge',
                        Streamlining: 'Streamlining of the purchasing process',
                        juryNotes: 'Jury Notes'
                },
                
                sosMed : {
                        Quality: 'Quality and creativity of social media content',
                        Community: 'Community growth and engagement',
                        impact: 'Two-way communication and customer relationships',
                        juryNotes: 'Jury Notes'
                },
                
                content: {
                        Innovation: 'Innovation and quality of digital content',
                        Effectiveness: 'Effectiveness of online marketing strategies',
                        Brand: 'Brand message consistency and resonance',
                        juryNotes: 'Jury Notes'
                },
                
                customer : {
                        Personalization: 'Personalization and customization',
                        Quality: 'Quality of responsive customer support',
                        Use: 'Use of data and analytics for improvements',
                        juryNotes: 'Jury Notes'
                },
                
                socialImpact : {
                        Demonstrated: 'Demonstrated initiatives for social and environmental impact',
                        ethical: 'Demonstrated ethical digital practices',
                        Measurable: 'Measurable contributions to social causes, environmental improvements, or community support',
                        juryNotes: 'Jury Notes'
                },
                
                resilience : {
                        ability: 'The ability to bounce back and endure in the face of challenges',
                        Endurance: 'Endurance in marketing efforts/social media despite obstacles',
                        Experimental: 'Experimental attitude (Lets try something different)',
                        juryNotes: 'Jury Notes'
                },
                
                inovation : {
                        Demonstrated: 'Demonstrated ability to innovate and adapt to changing digital landscapes',
                        Creation: 'Creation of original and compelling content or campaigns that stand out for their uniqueness and effectiveness.',
                        juryNotes: 'Jury Notes'
                },
                
                surprise : {
                        unique: 'A unique and unexpected element that sets the entry apart',
                        juryNotes: 'Jury Notes'
                }
        };
        return propertyMappings[category]?.[originalName] || originalName;
};

const DetailPage = ( ) => {
        const [detailData, setDetailData] = useState({});
        const { id } = useParams();
        useEffect(() => {
                async function getDetailItem() {
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
                        try {
                                const response = await Axios.get(`get-detail/${id}`);
                                const data = JSON.parse(response.data.companyInfo[0].score)
                                setDetailData(data);
                        } catch (error) {
                                console.error('Error fetching detail data:', error);
                        }
                }
                if (id) {
                        getDetailItem();
                }
        }, [id]);


        return(
                <>
                        <section>
                                {detailData && (
                                        <div className='container'>
                                                <h2 className='text-center text-uppercase'>Detail Scoring</h2>
                                                <table className="table table-bordered">
                                                        <tbody>
                                                                {Object.keys(detailData).map((category) => (
                                                                        <React.Fragment key={category}>
                                                                                <tr>
                                                                                        <td colSpan="2" className='text-center fw-semibold fs-5'>{formatCategoryName(category)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th scope="col">Aspect</th>
                                                                                        <th scope="col" className='text-center'>Score</th>
                                                                                </tr>
                                                                                {Object.keys(detailData[category]).map((property) => (
                                                                                        <>
                                                                                                <tr key={property}>
                                                                                                        <td>{formatPropertyName(property, category)}</td>
                                                                                                        <td className='text-center'>{detailData[category][property]}</td>
                                                                                                </tr>
                                                                                        </>
                                                                                        
                                                                                ))}
                                                                        </React.Fragment>
                                                                ))}
                                                        </tbody>
                                                </table>
                                        </div>
                                )} 
                        </section>
                </>
        );
}

export default DetailPage
