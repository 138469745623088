import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MetroTV from '../../awarding/metro.png'
import Zeals from '../../awarding/zeals.png'
import BDBA from '../../awarding/BDBA.png'
import img from '../../awarding/participate/elevate.png'
import { Link } from "react-router-dom";

import './awarding.css'
import { gsap } from "gsap";

const AwardingComp = () => { 
        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'left') {
                        animationProps.x = 60;
                } else {
                        animationProps.x = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };

        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'left';
                        if (index % 2 === 0) {
                                direction = 'right';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);
        const { t } = useTranslation();
        const awardPhotos = t('awardPhotos', { returnObjects: true });
        const whyChooseBDBA = t('whyChooseBDBA', { returnObjects: true });

        const [selectedImage, setSelectedImage] = useState('');

        const handleImageChange = (newImageSrc) => {
                setSelectedImage(newImageSrc);
        };

        return (  
                <>
                        <section className="pb-section advertise">
                                <div className="container margin-top margin-bottom">
                                        <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center fade-in-column">
                                                        <div>
                                                                <h1 className="fs-0 text-color-blue">{t('awarding.title')}</h1>
                                                                <p className="fs-4 text-muted">{t('content.bdba.desc')}</p> 
                                                        </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center fade-in-column">
                                                        <img src={BDBA} alt="bdba-logo" className="bdba-logo"/>
                                                </div>
                                        </div>
                                </div>
                        </section>
                        <section>
                                <div className="container margin-top margin-bottom">
                                        <p className="fs-4 text-center text-color-blue">{t('content.bdba.descTwo')}</p> 
                                        <div className="row mt-5">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                        <div className="shadow-box mx-2 rounded-pill d-flex justify-content-center">
                                                                <img src={MetroTV} alt="partner-logo" className="bdba-logo w-50"/>
                                                        </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                        <div className="shadow-box mx-2 rounded-pill d-flex justify-content-center">
                                                                <img src={Zeals} alt="partner-logo" className="bdba-logo w-50"/>
                                                        </div>  
                                                </div>
                                        </div>
                                </div>
                        </section>
                        
                        {/* <section class="mb-5 blue-bg">
                                <h1 className="fs-1 text-center text-color-blue fw-bold">Why Participate?</h1>
                                <div class="mt-5 py-5">
                                        <div class="container-md">
                                                <div class="row">
                                                        <div class="col-md-6 d-flex justify-content-center align-items-center">
                                                                <div class="p-4">
                                                                        <div class="accordion" id="accordionExample">
                                                                                {whyChooseBDBA.map((why, index) => (
                                                                                        <div class="accordion-item mb-3" key={index}>
                                                                                                <h2 class="accordion-header" data-imgsrc="" id={`headingOne-${index}`}>
                                                                                                        <button class={` accordion-button ${index !== 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOnebn-${index}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`collapseOnebn-${index}`} onClick={() => handleImageChange(why.img)}>
                                                                                                                <strong class="ms-3">{why.title}</strong>
                                                                                                        </button>
                                                                                                </h2>
                                                                                                <div id={`collapseOnebn-${index}`} class={`accordion-collapse collapse${index === 0 ? ' show' : ''}`}  aria-labelledby={`headingOne-${index}`} data-bs-parent="#accordionExample">
                                                                                                        <div class="accordion-body">
                                                                                                                {why.content}
                                                                                                                <img height="250" width="250" class="mx-auto mt-4 d-block d-md-none" src={require(`../../awarding/participate/${why.img}`)} alt="vector" />
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                ))}
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="col-md-6 d-flex justify-content-center align-items-center d-none d-md-block">
                                                                <img class="mt-5 w-100" id="benefitImage" src={require(`../../awarding/participate/${selectedImage}`)} alt="vector" />
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </section> */}

                        <section className="blue-bg">
                                <div className="container margin-top margin-bottom">
                                        <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-6">
                                                        <div className="mb-5 bdba-video">
                                                                <iframe
                                                                        className="embed-responsive-item"
                                                                        src="https://www.youtube.com/embed/6-9kbLbwC9k?si=cAyX8UR5aw62VJ5-"
                                                                        title="YouTube video player"
                                                                        frameborder="0"
                                                                        width="100%"
                                                                        height="100%"
                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                        allowfullscreen
                                                                ></iframe>
                                                        </div>
                                                        <div className="mb-5 bdba-video">
                                                                <iframe
                                                                        className="embed-responsive-item"
                                                                        src="https://www.youtube.com/embed/b8dqbkAXIio?si=IJzxKqPlJBrLcuB2"
                                                                        title="YouTube video player"
                                                                        frameborder="0"
                                                                        width="100%"
                                                                        height="100%"
                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                        allowfullscreen
                                                                ></iframe>
                                                        </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
                                                        <div id="awardingPhotos" className="carousel slide w-100 rounded-4" data-bs-ride="carousel">
                                                                <div className="carousel-inner rounded-4 shadow-box">
                                                                        {awardPhotos.map((award, index) => (
                                                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index} data-bs-interval="5000">
                                                                                        <img src={require(`../../awarding/${award.imageSrc}`)} className="d-block w-100" alt="bdba"/>
                                                                                </div>
                                                                        ))}
                                                                </div>
                                                                <button className="carousel-control-prev" type="button" data-bs-target="#awardingPhotos" data-bs-slide="prev">
                                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Previous</span>
                                                                </button>
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#awardingPhotos" data-bs-slide="next">
                                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Next</span>
                                                                </button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </section>

                        <section>
                                
                        </section>
                </>
        );
};

export default AwardingComp;
