import React, { useState, useEffect, useRef } from "react";
import {useContext} from 'react'
import {UserContext} from '../../Context/UserContext'
import Logo from "../../zeals-logo.png"
import { Link, useLocation } from "react-router-dom";

const JuriNavbar = () => {  
        const {user, logout} = useContext(UserContext);
        const [isNavbarOpen, setIsNavbarOpen] = useState(false); 
        const location = useLocation(); 
        const navbarRef = useRef(null);

        const handleNavbarToggle = () => {
                setIsNavbarOpen(!isNavbarOpen);
        };

        useEffect(() => {
                const closeNavbarOnClickOutside = (e) => {
                        if (navbarRef.current && !navbarRef.current.contains(e.target)) {
                                setIsNavbarOpen(false);
                        }
                };
        
                document.body.addEventListener("click", closeNavbarOnClickOutside);
        
                return () => {
                    document.body.removeEventListener("click", closeNavbarOnClickOutside);
                };
        }, []);

        useEffect(() => {
                setIsNavbarOpen(false);
        }, [location.pathname]);
        

        return (
                <>
                        <nav className="navbar navbar-expand-lg navbar-light" ref={navbarRef}>
                                <div className="container-fluid">
                                        <Link className="navbar-brand" to={`/`}>
                                                <img src={Logo} alt="Logo" className="zeals-logo" />
                                        </Link>
                                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavbarToggle}>
                                                <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarNav">
                                                <ul className="navbar-nav">
                                                {
                                                        user ?
                                                                <li className="nav-item">
                                                                        <Link className="nav-link" to={`/scoring-form`}>Scoring Form</Link>
                                                                </li>
                                                        :
                                                                <>
                                                                        <li className="nav-item">
                                                                                <Link className="nav-link" to={`/list-company`}>Company</Link>
                                                                        </li>
                                                                        <li className="nav-item">
                                                                                <Link className="nav-link" to={`/list-judger`}>Jury</Link>
                                                                        </li>
                                                                </>
                                                                
                                                }
                                                </ul>
                                                {
                                                        user ? 
                                                                <ul className="navbar-nav ms-auto">
                                                                        <li className="nav-item dropdown">
                                                                                <span className="nav-link dropdown-toggle" id="logOut" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                Hi!, {user.full_name}
                                                                                </span>
                                                                                <ul className="dropdown-menu" aria-labelledby="logOut">
                                                                                        <li className="d-flex">
                                                                                                <button type="submit" onClick={logout} className="dropdown-item">Log Out</button>    
                                                                                        </li>
                                                                                </ul>
                                                                        </li>
                                                                </ul> 
                                                        : 
                                                                <ul className="navbar-nav ms-auto">
                                                                <li className="nav-item dropdown">
                                                                                <span className="nav-link dropdown-toggle" id="logOut" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                Hi!, Admin
                                                                                </span>
                                                                                <ul className="dropdown-menu" aria-labelledby="logOut">
                                                                                        <li className="d-flex">
                                                                                                <button type="submit" onClick={logout} className="dropdown-item">Log Out</button>    
                                                                                        </li>
                                                                                </ul>
                                                                        </li>
                                                                </ul>
                                                }
                                        </div>
                                </div>
                        </nav>
                </>
        );
};

export default JuriNavbar;
