import React, {useState,useContext} from 'react';
import {UserContext} from '../../Context/UserContext';

const SignUpPage = () => {
        const {registerUser, wait} = useContext(UserContext);
        const [errMsg, setErrMsg] = useState(false);
        const [successMsg, setSuccessMsg] = useState(false);
        const [formData, setFormData] = useState({
                full_name: '',
                institution: '',
                email: '',
                title: '',
                phone_number: '',
                password: '',
                status: 'inactive',
                role: 'judger'
        });
        
        const handleInputChange = (event) => {
                const { id, value } = event.target;
                setFormData((prevData) => ({
                        ...prevData,
                        [id]: value,
                }));
        };
        
        const handleSubmit = async  (e) => {
                e.preventDefault();
                if(!Object.values(formData).every(val => val.trim() !== '')){
                        setSuccessMsg(false);
                        setErrMsg('Please Fill in all Required Fields!');
                        return;
                }
                const data = await registerUser(formData);
                console.log(data)
                if(data.success){
                        e.target.reset();
                        setSuccessMsg(data.data.messages);
                        setErrMsg(false);
                }
                else {
                        setSuccessMsg(false);
                        setErrMsg(data.data.messages);
                }
        };
        return (
                <section className='pb-first'>
                        <div className='container d-flex justify-content-center align-items-center '>
                                <div className='auth-box p-4 bg-white rounded shadow mt-5'>
                                        <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                        <label for="full_name" className="form-label fw-semibold">Full Name<span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" id="full_name" placeholder='Full Name' required value={formData.full_name} onChange={handleInputChange} />
                                                </div>
                                                <div className="mb-3">
                                                        <label for="institution" className="form-label fw-semibold">Institution<span className="text-danger">*</span></label>
                                                        <select className="form-select" aria-label="Default select example" id="institution" value={formData.institution} onChange={handleInputChange}>
                                                                <option>Select your institution</option>
                                                                <option value="campus">Campus</option>
                                                                <option value="company">Company</option>
                                                        </select>
                                                </div>
                                                <div className="mb-3">
                                                        <label for="email" className="form-label fw-semibold">Email address<span className="text-danger">*</span></label>
                                                        <input type="email" className="form-control" id="email" placeholder='Email Address' required value={formData.email} onChange={handleInputChange}/>
                                                </div>
                                                <div className="mb-3">
                                                        <label for="title" className="form-label fw-semibold">Title<span className="text-danger">*</span></label>
                                                        <select className="form-select" id="title" aria-label="Default select example" value={formData.title} onChange={handleInputChange}>
                                                                <option>Select your Title</option>
                                                                <option value="campus">Lecturer</option>
                                                        </select>
                                                </div>
                                                <div className="mb-3">
                                                        <label for="phone_number" className="form-label fw-semibold">Phone Number<span className="text-danger">*</span></label>
                                                        <input type="number" className="form-control" id="phone_number" required value={formData.phone_number} onChange={handleInputChange}/>
                                                </div>
                                                <div className="mb-3">
                                                        <label for="password" className="form-label fw-semibold">Password<span className="text-danger">*</span></label>
                                                        <input type="password" className="form-control" id="password"  required value={formData.password} onChange={handleInputChange}/>
                                                </div>
                                                {successMsg && <div className="alert alert-success" role='alert'>{successMsg}</div>}
                                                {errMsg && <div className="alert alert-danger" role='alert'>{errMsg}</div>}
                                                <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100 mb-3" disabled={wait}>Sign Up</button>
                                                <div className="mb-3">
                                                        <p className='text-center'>Already have an account? <a className='text-decoration-none text-color-blue fw-semibold' href='/login'>Login</a></p>
                                                </div>
                                        </form>
                                </div>
                        </div>
                </section>
        );
};

export default SignUpPage;
