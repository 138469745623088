import React, { useEffect } from "react";
import { gsap } from "gsap";
import "./influence.css";
import Client from '../Landing/client';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import AdvertiseComp from "../AdvertiseComp"

const InfluenceConnectComp = () => { 
        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'up') {
                        animationProps.y = 60;
                } else {
                        animationProps.y = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };
        
        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'up';
                        if (index % 2 === 0) {
                                direction = 'down';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);

        const { t } = useTranslation();
        const influenceCard = t('influenceCard', { returnObjects: true });

        return (  
                <>
                        <section className="pb-section">
                                <article>
                                        <div className="container margin-top margin-bottom" >
                                                <div className="row">
                                                        <h1 className="fs-1 text-uppercase text-color-blue text-center fw-bold fade-in-column">{t('influenceconnect.title')}</h1>
                                                        <div className="fade-in-column mt-3">
                                                                <h1 className="fs-1 text-center text-color-blue ">{t('influenceconnect.punchline')}</h1>
                                                                <p className="fs-4 text-center text-muted mt-3">{t('influenceconnect.desc')}</p>
                                                        </div>
                                                        
                                                </div>
                                        </div>
                                </article>
                        </section>
                        <section>
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <p className="fs-4 text-center" dangerouslySetInnerHTML={{ __html: t('influenceconnect.descTwo') }} />
                                </div>
                        </section>
                        <section className="blue-bg">
                                <div className="container margin-top margin-bottom">
                                        <div className="row">
                                                {influenceCard.map((influence, index) => (
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-5">
                                                                <div key={index} className="center-card text-color-blue">
                                                                        <img src={require(`../../influenceCard/${influence.imageSrc}`)} alt="card-logo" className="icon-card"/>
                                                                        <div className="card">
                                                                                <div className="card-body py-5">
                                                                                        <h5 className="fw-bold text-center fs-5 mt-3">{influence.title}</h5>
                                                                                        <p className="mt-3 text-center">{influence.desc}</p>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>
                        <AdvertiseComp />
                        <section>
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <p className="fs-4 text-center">{t('influenceconnect.invitation')}</p> 
                                        <Link to={`/contact`} className="d-flex justify-content-center text-decoration-none">
                                                <button type="button" class="btn btn-landing rounded-pill px-5 py-3 me-2  fw-semibold fs-5">{t('influenceconnect.button')}</button>
                                        </Link>
                                </div>
                        </section>
                </>
        );
};

export default InfluenceConnectComp;
