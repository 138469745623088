import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ReviewPage = () => {
        const { t } = useTranslation();
        const testimoniKOL = t('testimoniKOL', { returnObjects: true });

        return (
                <div className='container typing-testi'>
                        <p className="text-center fs-3 text-color-blue fw-bold">Let's hear our KOL Testimony</p>
                        <div className='row mt-5'>
                                {testimoniKOL.map((testimo, index) => (
                                        <div className='col-lg-4 col-md-4 col-sm-6 col-12 mb-5' key={index}>
                                                <div class="talk-bubble tri-right round btm-left">
                                                        <div class="talktext text-color-blue">
                                                                <p>{testimo.words}</p>
                                                        </div>
                                                </div>
                                                <div className='d-flex mt-3'>
                                                        <div className="avatar-container">
                                                                <img src={require(`../../../KOL/${testimo.image}`)} alt="avatar" className="avatar-img "/>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                                <p className="fw-semibold text-color-blue mb-0">{testimo.name}</p>
                                                        </div>
                                                </div>
                                        </div>
                                ))} 
                        </div>
                </div>
                
        );
}

export default ReviewPage;
