import React from "react";
import TestimonialComp from "../../Components/TestimonialComp"

const TestimonialPage = () => {
        return (
                <>
                        <main>
                                <TestimonialComp />
                        </main>
                </>
        );
};

export default TestimonialPage;
