import {createContext, useState, useEffect} from 'react'
import axios from 'axios'

export const UserContext = createContext();

export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

export const UserContextProvider = ({children}) => {
        const [theUser, setUser] = useState(null);
        const [theAdmin, setAdmin] = useState(null);
        const [wait, setWait] = useState(false);

        const registerUser = async ({ full_name, institution, email, title, phone_number, password, status, role }) => {
                setWait(true);
                try {
                        const response = await Axios.post('regis-judger', {
                                full_name,
                                institution,
                                email,
                                title,
                                phone_number,
                                password,
                                status,
                                role
                        });
                        if (response.data.status === 'success') {
                                setWait(false);
                                return { success: 1, data: response.data };
                        } else {
                                setWait(false);
                                return { success: 0, data: response.data };
                        }
                } catch (error) {
                        setWait(false);
                        console.error('An error occurred:', error);
              
                        if (error.message && error.message.includes('CORS')) {
                                return { success: 0, message: 'CORS Error: Unable to connect to the server' };
                        } else {
                                return { success: 0, message: 'Server Error' };
                        }
                }
        };

        const loginUser = async ({email,password}) => {
                setWait(true);
                try{
                        const {data} = await Axios.post('login-judger',{
                                email,
                                password 
                        });
                        if(data.status === 'success' && data.token){
                                localStorage.setItem('loginToken', data.token);
                                window.location.href = '/';
                                setWait(false);
                                return {success:1, user:data.user};
                        }
                        setWait(false);
                                return {success:0, message:data.message};
                        }
                catch(err){
                        setWait(false);
                        return {success:0, message:'Server Error!'};
                }
        }

        const LoggedInCheck = async () => {
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                if(loginToken){
                        try {
                                const { data } = await Axios.get('get-judger');
                                if (data.status === 'success' && data.user) {
                                        if(data.user.role === 'admin'){
                                                setAdmin(data.user)
                                        }else{
                                                setUser(data.user);
                                        }
                                } else {
                                        setUser(null);
                                        setAdmin(null)
                                }
                        } catch (error) {
                                setUser(null);
                                setAdmin(null)
                        }
                }
        }

        useEffect(() => {
                async function asyncCall(){
                        await LoggedInCheck();
                }
                asyncCall();
        },[]);

        const logout = () => {
                localStorage.removeItem('loginToken');
                setUser(null);
                setAdmin(null);
                window.location.href = '/login';
        }

        return (
                <UserContext.Provider value={{registerUser,loginUser,wait, user:theUser, admin:theAdmin ,LoggedInCheck,logout}}>
                        {children}
                </UserContext.Provider>
        );

}

export default UserContextProvider;