import React, {useState,useContext} from 'react';
import {UserContext} from '../../Context/UserContext';

const LoginPage = () => {
        const {loginUser, wait, LoggedInCheck} = useContext(UserContext);
        const [redirect, setRedirect] = useState(false);
        const [errMsg, setErrMsg] = useState(false);
        const [formData, setFormData] = useState({
                email:'',
                password:''
        });
        
        const onChangeInput = (e) => {
                setFormData({
                        ...formData,
                        [e.target.name]:e.target.value
                })
        }

        const submitForm = async (e) => {
                e.preventDefault();
                if(!Object.values(formData).every(val => val.trim() !== '')){
                        setErrMsg('Please Fill in all Required Fields!');
                        return;
                }

                const data = await loginUser(formData);
                if(data.success){
                        e.target.reset();
                        setRedirect('Redirecting...');
                        await LoggedInCheck();
                        return;
                }
                setErrMsg(data.message);
        }

        return (
                <section className='pb-first'>
                        <div className='container d-flex justify-content-center align-items-center '>
                                <div className='auth-box p-4 bg-white rounded shadow mt-5'>
                                        <form onSubmit={submitForm}>
                                                <div className="mb-3">
                                                        <label for="email" className="form-label fw-semibold">Email address<span className="text-danger">*</span></label>
                                                        <input type="email" name="email" className="form-control" id="email" placeholder='Email Address' required onChange={onChangeInput} value={formData.email}/>
                                                </div>
                                                <div className="mb-3">
                                                        <label for="password" className="form-label fw-semibold">Password<span className="text-danger">*</span></label>
                                                        <input type="password" name="password" className="form-control" id="password" required onChange={onChangeInput} value={formData.password}/>
                                                </div>
                                                {errMsg && <div className="alert alert-danger" role="alert">{errMsg}</div>}
                                                {redirect ? redirect :<button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100 mb-3" disabled={wait} >Login</button>}
                                        </form>
                                </div>
                        </div>
                        
                </section>
        );
};

export default LoginPage;
