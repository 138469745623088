import React from "react";
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Dummy from "../../dummy-business.jpg"

const Testimoni = () => { 
        const settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                arrows: false,
                responsive: [
                        {
                                breakpoint: 768,
                                settings: {
                                        slidesToShow: 1,
                                },
                        },
                ],
        };

        const { t } = useTranslation();
        const testimonials = t('testimonialBDBA', { returnObjects: true });
        return (  
                <>
                        <section>
                                <div className="container margin-top">
                                        <div className="row">
                                                <div className="col-lg-4 d-flex align-items-center">
                                                        <div>
                                                                <h1 className="fs-3 fw-bold">{t('testimonial.title')}</h1>
                                                                <p className="text-muted fs-5 mb-0">{t('testimonial.desc')}</p>
                                                        </div>
                                                </div>
                                                <div className="col-lg-8">
                                                        <Slider {...settings}>
                                                                {testimonials.map((testimonial, index) => (
                                                                        <div key={index} className="card custom-card shadow border-0">
                                                                                {/* <img src={require(`../../testimonial/BDBA/${testimonial.img}`)} className="card-img-top" alt="photos" /> */}
                                                                                <div className="card-body">
                                                                                        <p className="card-text">{testimonial.content}</p>
                                                                                        <h5 className="card-title mb-0">{testimonial.name}</h5>
                                                                                        <span className="text-muted">{testimonial.position}</span>
                                                                                </div>
                                                                        </div>
                                                                ))}
                                                        </Slider>
                                                </div>
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default Testimoni;
