import React, {useEffect, useState}  from "react";
import axios from 'axios'
import { Link } from "react-router-dom";
export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

const DashboardCompany = () => {
        const [company, setCompany] = useState([]);

        useEffect(() => {
                async function getJudgerList(){
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                        try {
                                const response = await Axios.get('get-company');
                                setCompany(response.data.company);
                        } catch (error) {
                                console.error('Error fetching scoring data:', error);
                        }    
                }
                getJudgerList();
        },[]);
        return (
                <>
                        <section>
                                <div className="container w-75 mx-auto mt-5">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark text-center text-uppercase">
                                                Participant Score
                                        </div>
                                        <table className="table ">
                                                <thead>
                                                        <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className="text-center">Award Category</th>
                                                                <th scope="col" className="text-center">Action</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {company.map((item, index) => (
                                                                <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{item.company_name}</td>
                                                                        <td className="text-center">{item.award_nomination}</td>
                                                                        <td className="d-flex justify-content-center">
                                                                                <Link to={`detail-score/${item.id}`} className="btn btn-landing rounded-pill fw-semibold px-5 mb-3">
                                                                                        Detail
                                                                                </Link>
                                                                        </td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                        </table>
                                </div>
                        </section>
                </>
        );
        
};

export default DashboardCompany;
