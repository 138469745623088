import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Logo from "../../zeals-logo.png"
import PlayStoreLogo from "../../playstore.png"
import ENG from "../../USA.png"
import ID from "../../ID.png"
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {  
        const { i18n, t } = useTranslation();
        
        const [isNavbarOpen, setIsNavbarOpen] = useState(false); 
        const location = useLocation(); 
        const navbarRef = useRef(null);
        const currentLanguage = i18n.language;
        const productsItem = t('productsItem', { returnObjects: true });
        const digiPreneurItem = t('digiPreneurItem', { returnObjects: true });

        const changeLanguage = (language) => {
                i18n.changeLanguage(language);
                setIsNavbarOpen(false);
        };

        const handleNavbarToggle = () => {
                setIsNavbarOpen(!isNavbarOpen);
        };

        useEffect(() => {
                const closeNavbarOnClickOutside = (e) => {
                        if (navbarRef.current && !navbarRef.current.contains(e.target)) {
                                setIsNavbarOpen(false);
                        }
                };
        
                document.body.addEventListener("click", closeNavbarOnClickOutside);
        
                return () => {
                    document.body.removeEventListener("click", closeNavbarOnClickOutside);
                };
        }, []);

        useEffect(() => {
                setIsNavbarOpen(false);
        }, [location.pathname]);
        

        return (  
                <>
                        <nav className="navbar navbar-expand-lg navbar-light" ref={navbarRef}>
                                <div className="container-fluid">
                                        <Link className="navbar-brand" to={`/`}>
                                                <img src={Logo} alt="Logo" className="zeals-logo" />
                                        </Link>
                                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavbarToggle}>
                                                <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarNav">
                                                <ul className="navbar-nav">
                                                        <li className="nav-item dropdown">
                                                                <span className="nav-link dropdown-toggle" id="procu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {t('nav.Products.title')}
                                                                </span>
                                                                <ul className="dropdown-menu p-2" aria-labelledby="procu">
                                                                        {productsItem.map((product, index) => (
                                                                                <li className="flex-column" key={index}>
                                                                                        <Link className="dropdown-item py-2" to={product.link}>
                                                                                                {product.item}
                                                                                                <p className="item-description text-muted mb-0 mt-2 " dangerouslySetInnerHTML={{ __html: product.desc }} />
                                                                                        </Link>
                                                                                </li>
                                                                        ))}
                                                                </ul>
                                                        </li>
                                                        <li className="nav-item dropdown">
                                                                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {t('nav.digipreneur.title')}
                                                                </span>
                                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        {digiPreneurItem.map((digiItem, index) => (
                                                                                <li key={index}>
                                                                                        <Link className="dropdown-item py-2" to={digiItem.link}>
                                                                                                {digiItem.item}
                                                                                                <p className="item-description text-muted mb-0 mt-2"  dangerouslySetInnerHTML={{ __html: digiItem.desc }} />
                                                                                        </Link>
                                                                                </li>
                                                                        ))}
                                                                        
                                                                        {/* <li>
                                                                                <Link className="dropdown-item py-2" to={`/testimoni`}>
                                                                                        Testimonial
                                                                                        <p className="item-description text-muted mb-0 mt-2 ">See our testimonial from our <br></br> clients</p>
                                                                                </Link>
                                                                        </li> */}
                                                                </ul>
                                                        </li>
                                                </ul>
                                                <ul className="navbar-nav ms-auto">
                                                        <li className="nav-item">
                                                                <Link className="nav-link" to={`/about`}>{t('nav.About')}</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                                <Link className="nav-link" to={`/contact`}>{t('nav.Contact')}</Link>
                                                        </li>
                                                        {/* <li className="nav-item">
                                                                <Link className="nav-link" to={`/login`}>Sign in</Link>
                                                        </li> */}
                                                        <li className="nav-item dropdown">
                                                                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {currentLanguage === 'id' ? (
                                                                                <img src={ID} alt="flag" className="flag mx-1" />
                                                                        ) : (
                                                                                <img src={ENG} alt="flag" className="flag mx-1" />
                                                                        )}
                                                                        {currentLanguage === 'id' ? 'Indonesia' : 'English'}
                                                                </span>
                                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        <li className="d-flex">
                                                                                <button className="dropdown-item" onClick={() => changeLanguage('id')}>
                                                                                        <img src={ID} alt="flag" className="flag mx-1"/>Indonesia
                                                                                </button>
                                                                        </li>
                                                                        <li>
                                                                                <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                                                                                        <img src={ENG} alt="flag" className="flag mx-1"/>English
                                                                                </button>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li className="nav-item playstore-logo">
                                                                <Link className="nav-link p-0" to={`login`}>
                                                                        <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100">Jury Login</button>
                                                                </Link>
                                                        </li>
                                                       
                                                        <li className="nav-item playstore-logo">
                                                                <Link className="nav-link p-0" to={`https://play.google.com/store/apps/details?id=asia.zeals.mobile.pwa`}>
                                                                        <img src={PlayStoreLogo} alt="Logo"/>
                                                                </Link>
                                                        </li>
                                                        
                                                </ul>
                                        </div>
                                </div>
                        </nav>
                </>
        );
};

export default Navbar;
